.header-panel {
    background: theme-color('light-dark');

    &__title {
        color: theme-color('primary');
    }

    &__subtitle {
        color: theme-color('secondary');
    }
}