.custom-button {
  min-width: 165px;
  margin: 1em auto;
  width: auto;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 35px 0 35px;
  font-size: 15px;
  background-color: #0B5394;
  color: white;
  text-transform: uppercase;
  font-weight: bolder;
  border: none;
  cursor: pointer;
  
  &:hover {
    background-color: white;
    color: #0B5394;
    border: 1px solid #0B5394;
  }
}

.custom-button__danger {
  @extend .custom-button;
  background-color: #f85032;

  &:hover {
    background-color: white;
    color: #f85032;
    border: 1px solid #f85032;
  }
}
  