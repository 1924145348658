.operation-form {
    background: #323232;
    border-radius: 6px;
    padding: 1em 1em;
    margin-top: 1em;
    margin-bottom: 1em;

    &__label {
        color: white;
        font-weight: 700;
    }

    &__select, &__date-picker {
        background: #1F4260;
        color: white;
    }

    &__results {
        margin-top: 1em;
        text-align: center;
    }
}