.cr-header {
  width: 80%;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .navbar-nav{
    margin-left: auto;
  }
}

