.nav-item {
  .nav-link {
    display: flex;
    align-items: center;

    &.active {
      color: #fff;
      background: theme-color('light-dark');
      opacity: 1;
    }
  }
}

.sidebar .nav a {
  transition: all 150ms ease-in;
}

.card-header {
  text-transform: uppercase;
}

.badge:empty {
  display: inline-block;
}