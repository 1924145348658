.inventory-receival-form {
    margin-top: 2em;
    width: 100%;
    font-weight: 550;
    margin-right: 1em;

    .col {
        margin: 0;
        align-self: center;
    }
}

.custom-button-container {
    display: flex;
}
