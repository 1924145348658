// override bootstrap 4 variables

$blue: #2196f3;
$indigo: #536dfe;
$purple: #9c27b0;
$pink: #ff4081;
$red: #f44336;
$orange: #ff9800;
$yellow: #ffeb3b;
$green: #4caf50;
$teal: #009688;
$cyan: #00bcd4;
$white: #fff;

$theme-colors: (
  "primary": #052762,
  "secondary": #0B5394,
  "success": #45b649,
  "danger": #f85032,
  "warning": #ffd700,
  "info": #00c9ff
);

$enable-gradients: true;

// card
//
$card-cap-bg: #fff;


// sidebar
$cr-sidebar-width: 280px;
